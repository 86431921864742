import { student_warning } from "../../../../assets/images/images";
import PreprocessAndRenderLatex from "../../../../components/ProcessRenderLatex";
import { convertSecondsToMinutes } from "../../../../utils/utlis";
import AnswerInputView from "./AnswerInputView";
import MCQsView from "./MCQsView";
import api, { wsURL } from "../../../../api/api";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../features/auth/AuthContext";
function QuestionNew({
  assignment,
  index,
  arrayIndex,
  currentStep,
  handleNext,
  handleBack,
  isLast,
  isFirst,
  close,
  totalSteps,
  ai_evaluation_json,
  questions,
}) {
  console.log("assignment", assignment);
  const { token } = useAuth();
  const [assignmentget, setAssignmentget] = useState([]);
  function escapeHtmlEntities(text) {
    // return text.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    return text
      ?.replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/_/g, "&#95;"); // Escape underscores
  }
  function switchQuestionView() {
    switch (assignment?.type) {
      case "FITB":
        return <AnswerInputView value={assignment?.student_answer} />;

      case "MCQ":
        return (
          <MCQsView
            options={assignment?.options}
            student_answer={assignment?.student_answer}
            ai_answer={assignment?.ai_answer}
          />
        );
      case "TF":
        return (
          <MCQsView
            options={assignment?.options}
            student_answer={assignment?.student_answer}
            ai_answer={assignment?.ai_answer}
          />
        );
      default:
        return <AnswerInputView value={assignment?.student_answer} />;
    }
  }

  return (
    <>
      <div className="AssignmentViewProgress-mock-test-status-container">
        {/* <div className="AssignmentViewProgress-mock-test-status-folder">
          <p>Answer Status</p>
          <div
            className={`AssignmentViewProgress-mock-test-status-correction ${ai_evaluation_json?.questions[index]?.evaluation}`}
          >
            <p>{ai_evaluation_json?.questions[index]?.evaluation}</p>
            <span>
              <i
                className={`fa-solid ${ai_evaluation_json?.questions[index]?.evaluation === "Correct"
                    ? "fa-check"
                    : "fa-x"
                  } `}
              ></i>
            </span>
          </div>
        </div> */}
        <div className="AssignmentViewProgress-mock-test-marks-obtained">
          <div className="AssignmentViewProgress-mock-test-status-folder">
            {/* <p>Marks Obtained</p> */}
            <p>Score</p>
            <div
              className={`AssignmentViewProgress-mock-test-status-correction ${ai_evaluation_json?.questions[index]?.evaluation}`}
              style={{
                color:
                ai_evaluation_json?.questions[index]
                    ?.evaluation === "Correct"
                    ? "#28991f"
                    : ai_evaluation_json?.questions[index]
                        ?.evaluation === "Incorrect"
                    ? "#b24747"
                    : "#FFA800",
                backgroundColor:
                  ai_evaluation_json?.questions[index]
                    ?.evaluation === "Correct"
                    ? "#e6ffe5"
                    : ai_evaluation_json?.questions[index]
                        ?.evaluation === "Incorrect"
                    ? "#ffd6d6"
                    : "#FFE5CC",
              }}
            >
              {/* <p>{ai_evaluation_json?.questions[index]?.evaluation}</p> */}
              <span>
                {ai_evaluation_json?.questions[index]?.marks_obtained}/
                {ai_evaluation_json?.questions[index]?.marks}
                {/* <i
                  className={`fa-solid ${ai_evaluation_json?.questions[index]?.evaluation === "Correct"
                      ? "fa-check"
                      : "fa-x"
                    } `}
                ></i> */}
              </span>
            </div>
          </div>
          <div className="AssignmentViewProgress-mock-test-status-duration-folder">
            <p>Duration</p>
            <div className="AssignmentViewProgress-mock-test-status-correction AssignmentViewProgress-mock-test-status-time-duration">
              <p>{convertSecondsToMinutes(assignment?.time_taken)}</p>
              {/* <p>{index > 0 ? convertSecondsToMinutes(arrayIndex[index - 1] ? assignment.time_taken - arrayIndex[index - 1].time_taken : '') : convertSecondsToMinutes(assignment.time_taken)}</p>
              {assignment?.time_taken && Math.floor((assignment?.time_taken ?? 0) / 60) > 5 && (
                <img src={student_warning} alt="" />
              )} */}
            </div>
            {/* <span></span> */}
          </div>
        </div>
        {/* <div className="AssignmentViewProgress-mock-test-status-folder">
          <p>Time Taken</p>
          <div className="AssignmentViewProgress-mock-test-status-correction AssignmentViewProgress-mock-test-status-time-duration">
            <p>{index > 0 ? convertSecondsToMinutes(arrayIndex[index - 1] ?  assignment.time_taken - arrayIndex[index - 1].time_taken : '') : convertSecondsToMinutes(assignment.time_taken)}</p>
            {assignment?.time_taken && Math.floor((assignment?.time_taken ?? 0) / 60) > 5 && (
              <img src={student_warning} alt="" />
            )}
          </div>
        </div> */}
        {close}
      </div>
      <div className="AssingmentMCQs-mock-test-question-answer-container">
        <h2>
          {/* <span className="AssingmentMCQs-question">
            Q.<span> {index + 1}:</span>  {<PreprocessAndRenderLatex content={assignment?.question.replace(/(\\\()(.*?)(\\\))/g, (match, p1, p2, p3) => `$$${p1}${p2}${p3}$$`)} />}
          </span> */}
          <span className="AssingmentMCQs-question">Q. {index + 1} :</span>
          <span className="AssingmentMCQs-answers">
            <PreprocessAndRenderLatex
              content={escapeHtmlEntities(
                assignment?.question?.replace(
                  /(?<!\$)\$(?!\$)(.*?)\$+(?!\$)/g,
                  (match, p1) => `$$${p1}$$`
                )
              )}
            />
          </span>
        </h2>
        {switchQuestionView()}
        <div className="AssingmentMCQs-mock-test-Ai-feedback-wrapper">
          <h2 className="AssingmentMCQs-mock-test-title">AI feedback</h2>
          <div className="AssingmentMCQs-mock-test-Ai-feedback-answer">
            <p>
              <PreprocessAndRenderLatex
                content={escapeHtmlEntities(
                  questions && questions[index]?.ai_feedback
                )}
              />
            </p>
            {/* <p>{assignmentget?.submission?.ai_evaluation_json?.questions[index]?.ai_feedback}</p> */}
          </div>
        </div>
        <div className="AssingmentMCQs-mock-test-answer-btn-folder">
          <button disabled={isFirst} onClick={handleBack}>
            <span className="AssingmentMCQs-mock-test-answer-left-arrow-btn">
              <i className="fa-solid fa-arrow-left"></i>
            </span>
            Back
          </button>

          {!isLast && (
            <button disabled={isLast} onClick={handleNext}>
              {isLast ? "" : "Next"}
              <span className="AssingmentMCQs-mock-test-answer-right-arrow-btn">
                <i className="fa-solid fa-arrow-right"></i>
              </span>
            </button>
          )}
        </div>
      </div>
      <div className="pagination">
        <span>
          ❮ {currentStep} / {totalSteps} ❯
        </span>
      </div>
    </>
  );
}

export default QuestionNew;
